function useStateContextCallbacks() {
  return {
    updateGcmConsentTypeChecked: (_ref, type, checked) => {
      let {
        gcmConsent
      } = _ref;
    } /* Silence is golden... Until you have PRO Version! */
  };
}

export { useStateContextCallbacks };