import { useEffect, useState } from "react";
import { useBanner } from "../../..";
import { createTcfModel } from "@devowl-wp/cookie-consent-web-client";
function useBannerHistorySelect() {
  const {
    history: oldHistory,
    isGcm,
    set
  } = useBanner();
  return (_ref, history) => {
    let {
      tcf,
      gcmConsent = [],
      ...rest
    } = _ref;
    const additionalChanges = {
      // In case we previously used non-TCF functionality, do not show the TCF banner
      isTcf: !!tcf,
      tcf: null,
      gcmConsent: [],
      history: history || oldHistory
    };
    if (process.env.IS_TCF === "1" && tcf) {
      Object.assign(additionalChanges, {
        tcf: "gvl" in tcf ? tcf : createTcfModel(tcf)
      });
    }

    /* Silence is golden... Until you have PRO Version! */

    set({
      ...rest,
      ...additionalChanges
    });
  };
}
function useBannerHistory() {
  const banner = useBanner();
  const selectHistoryEntry = useBannerHistorySelect();
  const {
    consent,
    groups,
    tcf,
    gcmConsent,
    activeAction,
    fetchHistory
  } = banner;

  // Save the current consent and group settings to restore
  const [originalContext, setOriginalContext] = useState({
    consent,
    groups,
    tcf,
    gcmConsent
  });

  // Listen to consent changes and save it as original consent (only when non-history)
  useEffect(() => {
    if (!activeAction) {
      setOriginalContext({
        consent,
        groups,
        tcf,
        gcmConsent
      });
    }
  }, [consent, groups, tcf, activeAction]);
  useEffect(() => {
    async function fetch() {
      const result = await fetchHistory();

      // Initially select first item
      if (result.length) {
        selectHistoryEntry(result[0].context, result);
      } else {
        selectHistoryEntry({
          consent: [],
          groups: [],
          gcmConsent: []
        }, result);
      }
    }
    if (activeAction === "history") {
      // Save state of original consent
      setOriginalContext({
        consent,
        groups,
        tcf,
        gcmConsent
      });
      fetch();
    }
    return () => {
      // If we close the history, restore the original state
      selectHistoryEntry === null || selectHistoryEntry === void 0 ? void 0 : selectHistoryEntry(originalContext);
    };
  }, [activeAction]);
}
export { useBannerHistory, useBannerHistorySelect };